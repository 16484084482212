import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MarkdownModule } from "ngx-markdown";
import { RouterModule, Routes } from "@angular/router";
// import { CountToModule } from 'ngx-countto';
import { CountoModule } from 'angular2-counto';
import { NgAnimatedCounterModule } from '@bugsplat/ng-animated-counter';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';

import { NavComponent } from "./nav/nav.component";
import { ArticlesComponent } from "./articles/articles.component";
import { ArticleComponent } from "./article/article.component";
import { CategoryComponent } from "./category/category.component";
import { ProjectsComponent } from './projects/projects.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BodComponent } from './bod/bod.component';
import { ServiceComponent } from './service/service.component';
import { InfoGraphicComponent } from './bod/infographic/infographic.component';
import { VerticalComponent } from './about-us/vertical/vertical.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ProjectDetailComponent } from './projects/detail/detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FormsModule } from '@angular/forms';
import { CareerComponent } from './career/career.component';

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutUsComponent },
  { path: "board-of-directors", component: BodComponent },
  { path: "projects", component: ProjectsComponent },
  { path: "projects/:categoryId", component: ProjectsComponent },
  { path: "services", component: ServiceComponent },
  { path: "article/:id", component: ArticleComponent },
  { path: "project-detail/:id", component: ProjectDetailComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "careers", component: CareerComponent },
  { path: '', pathMatch: 'full', redirectTo: '/' },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ArticlesComponent,
    ArticleComponent,
    CategoryComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    HomeComponent,
    AboutUsComponent,
    BodComponent,
    ServiceComponent,
    FooterComponent,
    InfoGraphicComponent,
    VerticalComponent,
    GalleryComponent,
    ContactUsComponent,
    CareerComponent
  ],
  imports: [
    MarkdownModule.forRoot(),
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    CountoModule,
    NgxHmCarouselModule,
    BrowserAnimationsModule,
    NgAnimatedCounterModule
    // CountToModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
