import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import CATEGORIES_QUERY from "../apollo/queries/category/categories";
import PROJECTS_QUERY from "../apollo/queries/project/projects";
import { Subscription } from "rxjs";
import { AppConfig } from '../_config/app.config';

@Component({
  selector: 'bod',
  templateUrl: './bod.component.html',
  styleUrls: ['./bod.component.css']
})
export class BodComponent implements OnInit {
  data: any = {};
  loading = true;
  errors: any;

  private queryCategories: Subscription;
  queryProjects: Subscription;
  projectData: any = {};
  apiUrl: string;

  constructor(private apollo: Apollo) { 
    this.apiUrl = new AppConfig().apiUrl;
  }

  ngOnInit() {}
}
