import { Component, OnInit, ViewChild } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import LOCATIONS_QUERY from "../apollo/queries/location/location";
import { Subscription } from "rxjs";
import JOBS_QUERY from "../apollo/queries/job/job";
import { Candidate } from "./model/job.model";
import { ContactService } from "../contact-us/service/contact.service";

@Component({
  selector: "career",
  templateUrl: "./career.component.html",
  styleUrls: ["./career.component.css"],
})
export class CareerComponent implements OnInit {
  @ViewChild("fileInput", { static: false }) fileInput: HTMLInputElement;
  emailExists: any;
  data: any = {};
  loading = true;
  errors: any;
  applyingJob: any;
  Candidate: Candidate = new Candidate();
  selectedFile: File | null = null;

  private queryProjects: Subscription;
  queryLocation: Subscription;
  locationData: any = {};
  current: any = undefined;
  queryJobs: Subscription;
  popUpView: [];

  constructor(private apollo: Apollo, private contactService: ContactService) {}

  apply(item) {
    this.applyingJob = item;
  }

  sendMail(e) {
    console.log(e.form);
    console.log(e.form.value);
    this.loading = true;

    const formData = new FormData();
    formData.append("firstName", this.Candidate.firstName);
    formData.append("lastName", this.Candidate.lastName);
    formData.append("email", this.Candidate.email);
    formData.append("mobile", this.Candidate.mobile);
    formData.append("message", this.Candidate.message);
    formData.append("attachment", this.Candidate.attachment);

    this.contactService.sendJobMail(formData).subscribe((res) => {
      this.loading = false;
    });
    // form.form.reset();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.Candidate.attachment = this.selectedFile;
    }
  }

  getJob(location?) {
    this.queryJobs = this.apollo
      .watchQuery({
        query: JOBS_QUERY,
      })
      .valueChanges.subscribe((result: any) => {
        if (location) {
          this.current = location;
          this.data.jobs = result!.data!.jobs!.data!.filter(
            (item) => item!.attributes!.location!.data!.id == location.id
          );
        } else {
          this.current = undefined;
          this.data.jobs = result!.data!.jobs!.data;
        }

        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  ngOnInit() {
    this.getJob();
    this.queryLocation = this.apollo
      .watchQuery({
        query: LOCATIONS_QUERY,
      })
      .valueChanges.subscribe((result: any) => {
        console.log(result);

        this.locationData = result!.data!.locations!.data;

        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  ngOnDestroy() {
    this.queryJobs.unsubscribe();
  }
}
