import gql from "graphql-tag";

const PROJECTS_QUERY = gql`
  query Projects {
    projects {
      data {
        id
        attributes {
          name
          location
          client
          architect
          total_work
          floors
          scope_of_work
          category {
            data {
              id
              attributes {
                title
              }
            }
          }
          image {
            data {
              id,
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
// const PROJECTS_QUERY = gql`
//   query Projects {
//     projects {
//       id
//       name
//       location
//       client
//       architect
//       total_work
//       floors
//       scope_of_work
//       category {
//         id
//         title
//       }
//       image {
//         url
//       }
//     }
//   }
// `;

export default PROJECTS_QUERY;
