import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { AppConfig } from "./_config/app.config";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";

// const uri = 'http://localhost:1337/graphql'; // <-- add the URL of the GraphQL server here
const uri = new AppConfig().apiUrl + "graphql"; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({
      uri,
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.graphqlToken}`,
      }),
    }),
    cache: new InMemoryCache(
      {addTypename: false}
    ),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first', // Use cache-and-network for better performance
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'cache-first', // Use cache-first for better performance
        errorPolicy: 'ignore',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
