import gql from "graphql-tag";

const ADDRESSES_QUERY = gql`
  query Addresses {
    addresses {
      data {
        id
        attributes {
          detail
          is_site_address
        }
      }
    }
  }
`;

export default ADDRESSES_QUERY;
