export class Job {

}

export class Candidate {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    attachment: string | any;
    message: string;
}