// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'http://localhost:1337',
  graphqlToken: '40cb360140289d61fbb833cf2511276d57eff34ade1b520ca60dfa065bead0b421133fbb9848dcdbb588c588b9fa7e8f239a19af57888f5bff07ca951a3dec25763dfdae8aa45836b77f9208c389b4d4008335e2a6173883c01896a83163a0747521f3cae0a96188c93403a0c5e280c13322a4033abefa4178c8babb521cd8e7',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
