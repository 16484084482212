import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CountToService } from "ngx-countto";
import { Subscription } from "rxjs";
import { AppConfig } from "../_config/app.config";
import PROJECTS_QUERY from "../apollo/queries/project/projects";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [CountToService],
})
export class HomeComponent implements OnInit {
  data: any = {};
  loading = true;
  errors: any;
  // public params: NgAnimatedCounterParams = { start: 20, end: 50, interval: 10 };

  // private queryCategories: Subscription;
  queryProjects: Subscription;
  projectData: any[] = [];
  apiUrl: string;

  constructor(private apollo: Apollo, private countToService: CountToService) {
    this.apiUrl = new AppConfig().apiUrl;
  }

  ngOnInit() {
    // this.queryCategories = this.apollo
    //   .watchQuery({
    //     query: CATEGORIES_QUERY,
    //   })
    //   .valueChanges.subscribe((result: any) => {
    //     console.log(result);

    //     this.data = result.data;

    //     this.loading = result.loading;
    //     this.errors = result.errors;
    //   });

    this.apollo
    .watchQuery({
      query: PROJECTS_QUERY,
    })
    .valueChanges.subscribe({
      next: (result: any) => {
        this.projectData = result.data.projects.data; // Ensure this is an array
        this.loading = result.loading;
        this.errors = result.errors;
      },
      error: (error) => {
        console.error("Apollo query error:", error);
      }
    });

    this.countToService.start(1);
  }
  ngOnDestroy() {
    if (this.queryProjects) {
      this.queryProjects.unsubscribe();
    }
  }
}
