import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import ADDRESSES_QUERY from "../apollo/queries/address/addresses";
import { Subscription } from "rxjs";
import { AppConfig } from "../_config/app.config";
import { Contact } from "./model/contact.model";
import { ContactService } from "./service/contact.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  data: any[] = [];
  loading = true;
  errors: any;
  Contact: Contact = new Contact();
  apiUrl: string;
  queryAddress: Subscription;
  emailExists: any;
  constructor(private apollo: Apollo, private contactService: ContactService) {
    this.apiUrl = new AppConfig().apiUrl;
  }

  ngOnInit() {
    this.queryAddress = this.apollo
      .watchQuery({
        query: ADDRESSES_QUERY,
      })
      .valueChanges.subscribe((result: any) => {

        this.data = result!.data!.addresses!.data;
        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  sendMail(form: NgForm) {
    this.loading = true;
    this.contactService.sendMail(this.Contact).subscribe((res) => {
      this.loading = false;
    });
    form.form.reset();
  }
  ngOnDestroy() {
    if (this.queryAddress) {
      this.queryAddress.unsubscribe();
    }
  }
}
