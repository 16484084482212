import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from "apollo-angular";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  data: any = {};
  loading = true;
  errors: any;

  private queryCategories: Subscription;
  currentPath: string;

  constructor(private activatedRoute: ActivatedRoute, private apollo: Apollo) { }

  ngOnInit() {
    this.activatedRoute.url.subscribe(url => {
      if (url.length !== 0) {
        this.currentPath = url[0].path;
      } else {
        this.currentPath = '';
      }
    });
    
  }
}
