import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
// import ARTICLES_QUERY from "../apollo/queries/article/projects";
import CATEGORIES_QUERY from "../apollo/queries/category/categories";
import { Subscription } from "rxjs";
import GALLERIES_QUERY from "../apollo/queries/gallery/gallery";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"],
})
export class GalleryComponent implements OnInit {
  data: any = {};
  loading = true;
  errors: any;

  queryCategories: Subscription;
  categoryData: any = {};
  current: any = undefined;
  queryGalleries: Subscription;
  popUpView: [];

  constructor(private apollo: Apollo) {}

  getGallery(category?) {
    this.queryGalleries = this.apollo
      .watchQuery({
        query: GALLERIES_QUERY,
      })
      .valueChanges.subscribe((result: any) => {
        if (category) {
          this.current = category;
          this.data.galleries = result!.data!.galleries!.data!.filter(
            (item) => item!.attributes!.category!.data!.id == category.id
          );
        } else {
          this.current = undefined;
          this.data.galleries = result!.data!.galleries!.data;
        }

        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  ngOnInit() {
    this.getGallery();
    this.queryCategories = this.apollo
      .watchQuery({
        query: CATEGORIES_QUERY,
      })
      .valueChanges.subscribe((result: any) => {
        this.categoryData = result!.data!.categories!.data;
        // console.log(this.categoryData);

        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  ngOnDestroy() {
    this.queryGalleries.unsubscribe();
  }
}
