import gql from "graphql-tag";

const GALLERIES_QUERY = gql`
  query Galleries {
    galleries {
      data {
        id
        attributes {
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
          category {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
// const GALLERIES_QUERY = gql`
//   query Galleries {
//     galleries {
//       image {
//         url
//       }
//       category {
//         title
//       }
//     }
//   }
// `;

export default GALLERIES_QUERY;
