import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfig } from "src/app/_config/app.config";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private http: HttpClient) {}

  public sendMail(body): Observable<any> {
    // const url = 'https://dry-caverns-08449.herokuapp.com/';
    // const url = 'http://localhost:3000/';
    const url = new AppConfig().apiUrl;
    // return this.http.post(url + 'email', body);
    return this.http.post(`${url}api/email`, body);
  }

  public sendJobMail(formData: FormData): Observable<any> {
    const url = new AppConfig().apiUrl;
    return this.http.post(`${url}api/email/job`, formData);
  }
}
