import gql from "graphql-tag";

const LOCATIONS_QUERY = gql`
  query Locations {
    locations {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
// const LOCATIONS_QUERY = gql`
//   query Locations {
//     locations {
//       id
//       name
//     }
//   }
// `;

export default LOCATIONS_QUERY;
