import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'vertical',
    templateUrl: './vertical.component.html',
    styleUrls: ['./vertical.component.css']
})
export class VerticalComponent implements OnInit {
    @Input() name;
    ngOnInit() {

    }
}