import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
// import ARTICLES_QUERY from "../apollo/queries/article/projects";
import PROJECT_QUERY from "../../apollo/queries/project/project";
import { Subscription } from "rxjs";
import CATEGORIES_QUERY from "../../apollo/queries/category/categories";
import {
  fadeIn,
  fadeOut,
  scaleIn,
  scaleOut,
} from "./carousel.animations";
import { trigger, transition, useAnimation } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: "project-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css", "./carousel.scss"],
  animations: [
    trigger("slideAnimation", [
      /* scale */
      transition("void => *", [useAnimation(scaleIn, { params: { time: '500ms' } })]),
      transition("* => void", [useAnimation(scaleOut, { params: { time: '500ms' } })]),
    ])
  ]
})
export class ProjectDetailComponent implements OnInit, AfterViewInit {
  data: any = {};
  loading = true;
  errors: any;
  leftProjectsCount: any;
  leftProjects: any[];
  rightProjects: any[];
  currentSlide = 0;

  private queryProjects: Subscription;
  queryCategories: Subscription;
  categoryData: any = {};
  current: any = undefined;
  ID: any;

  constructor(private apollo: Apollo, private route: ActivatedRoute) { }
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.data!.attributes!.image!.data!.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.data!.attributes!.image!.data!.length ? 0 : next;
  }

  getProjectById() {
    this.queryProjects = this.apollo
      .watchQuery({
        query: PROJECT_QUERY,
        variables: {
          id: this.route.snapshot.params.id
        }
      })
      .valueChanges.subscribe((result: any) => {
        // console.log(result);
        this.data = result!.data!.project!.data;
        this.loading = result.loading;
        this.errors = result.errors;
        

      });
    this.autoSlide();
  }

  autoSlide() {
    setInterval(() => {
      this.onNextClick();
    }, 3000);
  }

  ngOnInit() {
    
  }

  async ngAfterViewInit() {
    await this.getProjectById();
    // let firstAccordian = document.getElementById('firstAccordian');
    // firstAccordian.click(); 
  }

  ngOnDestroy() {
    this.queryProjects.unsubscribe();
  }
}
