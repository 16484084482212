import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Apollo } from "apollo-angular";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  data: any = {};
  loading = true;
  errors: any;

  private queryCategories: Subscription;
  currentPath: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private apollo: Apollo) {
  }


  ngOnInit() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationStart) {
        
        this.currentPath = val.url;
      }
    });
  }
}
