import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import PROJECTS_QUERY from "../apollo/queries/project/projects";
import { Subscription } from "rxjs";
import CATEGORIES_QUERY from "../apollo/queries/category/categories";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
})
export class ProjectsComponent implements OnInit {
  private queryProjects: Subscription;
  queryCategories: Subscription;
  categoryData: any = {};
  current: any = undefined;
  ID: any;
  data: any = { projects: [] };
  loading: boolean = false;
  isEmpty: boolean = false;
  errors: any;

  constructor(private apollo: Apollo, private route: ActivatedRoute) {}

  getProjects(category?) {
    this.loading = true;
    this.queryProjects = this.apollo
      .watchQuery({
        query: PROJECTS_QUERY,
      })
      .valueChanges.subscribe((result: any) => {
        if (category) {
          this.current = category;
          this.data.projects = result.data.projects.data.filter(
            (item) => item.attributes.category.data.id == category.id
          );
        } else {
          this.current = undefined;
          this.data.projects = result.data.projects.data;
        }
        this.isEmpty = this.data.projects.length < 1;
        this.loading = result.loading;
        this.errors = result.errors;
      });
  }

  ngOnInit() {
    this.ID = this.route.snapshot.params['categoryId'];
    this.loading = true;
    this.queryCategories = this.apollo
      .watchQuery({
        query: CATEGORIES_QUERY,
      })
      .valueChanges.subscribe({
        next: (result: any) => {
          this.categoryData = result.data.categories;
          this.loading = result.loading;
          this.errors = result.errors;
          if (this.ID) {
            const category = this.categoryData.data.find(cat => cat.id === this.ID);
            this.getProjects(category);
          } else {
            this.getProjects();
          }
        },
        error: (error) => {
          console.error("Apollo query error:", error);
        }
      });
  }

  ngOnDestroy() {
    if (this.queryProjects) {
      this.queryProjects.unsubscribe();
    }
    if (this.queryCategories) {
      this.queryCategories.unsubscribe();
    }
  }
}
