import gql from "graphql-tag";

const JOBS_QUERY = gql`
  query Jobs {
    jobs {
      data {
        id
        attributes {
          title
          vacancy
          qualification
          salary
          role_responsibility
          address
          location {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
// const JOBS_QUERY = gql`
// query Jobs {
//   jobs {
//     id
//     title
//     vacancy
//     qualification
//     salary
//     role_responsibility
//     address
//     location {
//       id
//       name
//     }
//   }
// }
// `;

export default JOBS_QUERY;
