import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'infographic',
    templateUrl: './infographic.component.html',
    styleUrls: ['./infographic.component.scss']
})
export class InfoGraphicComponent implements OnInit {
    ngOnInit() {

    }
}